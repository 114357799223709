<template>
  <el-dialog title="申请详情" :visible.sync="dialogState" @close="close">
    <el-table
        :data="clothesList"
        style="width: 100%">
<!--      <el-table-column-->
<!--          type="index"-->
<!--          align="center"-->
<!--          width="50">-->
<!--      </el-table-column>-->
      <el-table-column
          label="图片"
          width="180"
          prop="tenantName" align="center">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              @click="openStyleImages(scope.row.styleId)"
              :src="`https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/${scope.row.styleImage}?imageMogr2/rquality/60`"
              fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column
          label="名称"
          align="center">
        <template slot-scope="scope">
          <p>{{`${scope.row.styleType}-${scope.row.styleName}-${scope.row.clothesSize}-${scope.row.clothesNo}`}}</p>
        </template>
      </el-table-column>
      <el-table-column label="店铺" align="center" prop="shopName"/>
      <el-table-column label="位置" align="center" prop="positionName"/>
<!--      <el-table-column label="操作" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--              size="mini"-->
<!--              @click="delShoppingCart(scope.row)">移除</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: "transport-list-clothes",
  data(){
    return{
      dialogState:this.state,
      clothesList:[],
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    orderNumber:{
      type: String,
      required: true,
    }
  },
  created() {
    this.queryClothesVOByIds();
  },
  methods:{
    //根据婚纱ID查询婚纱信息
    queryClothesVOByIds(){
      this.$axios({
        method: "GET",
        url: "/libClothesTransport/queryClothesListByOrderNum",
        params: {
          orderNumber: this.orderNumber,
        }
      }).then(response => {
        this.clothesList=response.data.data
      })
    },
    openStyleImages(styleId){
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: styleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    close(){
      this.$emit("close");
    },
  },
}
</script>

<style scoped>

</style>